export const calculateDiscount = (originalPrice: number, discountedPrice: number) => {
  if (originalPrice <= 0 || discountedPrice < 0) {
    throw new Error('Prices must be positive numbers');
  }

  if (discountedPrice >= originalPrice) {
    return 0;
  }

  return Math.round(((originalPrice - discountedPrice) / originalPrice) * 100);
};
